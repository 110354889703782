<template>
  <div class="page-box">
    <van-field
      v-model="form.content"
      rows="2"
      autosize
      label="留言"
      type="textarea"
      maxlength="200"
      placeholder="留下您宝贵的意见，帮助我们不断完善！"
      show-word-limit
      height="135px"
    />

    <van-field
      v-model="form.mobile"
      label="电话"
      type="number"
      placeholder="选填，以便联系到您"
      style="margin-top:10px;"
    />

    <van-button
      class="submit-btn"
      color="#51B464"
      round
      type="info"
      @click="submit"
    >
      提交
    </van-button>
  </div>
</template>

<script>
export default {
  name: 'Test',
  components: {
  },
  data () {
    return {
      form: {
        mobile: '',
        content: ''
      },
      isGlobalPage: false
    }
  },
  computed: {
  },
  created () {
  },
  methods: {
    async submit () {
      if (this.form.content) {
        let res = await this.$http.homeApi.feedback(this.form)
        if (res.code === 200) {
          this.$toast.success('感谢您的反馈~')
          this.form = {
            content: '',
            mobile: ''
          }
        }
      } else {
        this.$toast('请完善信息')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page-box {
  padding-top: 30px; 
  .submit-btn {
    width: 345px;
    margin: 50px auto 0;
    display: block;
  }
}
</style>
